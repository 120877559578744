// メニュー関係
.-project {
  width: 34px;
  height: 34px;
  background-image: url('/assets/icn_footer_project_off.svg');
  background-size: cover;
}

.-task {
  width: 34px;
  height: 34px;
  background-image: url('/assets/icn_footer_task_off.svg');
  background-size: cover;
}

.-notification {
  width: 34px;
  height: 34px;
  background-image: url('/assets/icn_footer_notification_off.svg');
  background-size: cover;
}

.-quick {
  width: 34px;
  height: 34px;
  background-image: url('/assets/icn_footer_quick_off.svg');
  background-size: cover;
}

// 次画面遷移
.-arrow-right {
  width: 17px;
  height: 24px;
  background-image: url('/assets/icn_arrow_right.svg');
  background-size: cover;
}

// ソート
.-sortorder {
  width: 20px;
  height: 20px;
  background-image: url('/assets/icn_sortorder.svg');
  background-size: cover;
}

// ソート
.-filter {
  width: 20px;
  height: 20px;
  background-image: url('/assets/icn_filter.svg');
  background-size: cover;
}

// TaskList
.-task-list {
  width: 27px;
  height: 27px;
  background-image: url('/assets/icn_task.svg');
  background-size: cover;
}

.-icon-project {
  width: 50px;
  min-width: 50px;
  height: 50px;
  background-image: url('/assets/icn_project.svg');
}

.-icon-project-mini {
  width: 16px;
  min-width: 16px;
  height: 16px;
  background-image: url('/assets/icn_project.svg');
  background-size: cover;
}

.-icon-task {
  width: 27px;
  height: 27px;
  background-image: url('/assets/icn_task.svg');
  background-size: cover;
}

.-icon-task-mini {
  width: 16px;
  height: 16px;
  background-image: url('/assets/icn_task.svg');
  background-size: cover;
}
