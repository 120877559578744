@use 'mixin' as m;

.wrap {
  height: calc(100dvh - var(--header-height) - var(--footer-height));
  background: var(--base-background-color);
  transition: all 0.3s var(--default-ease-in-out);

  @include m.mq(pc) {
    margin-top: var(--header-height);
  }

  @include m.mq(sp) {
    margin-top: var(--header-height-sp);
  }
}

.drawer_closed {
  margin-left: 0;
}

.drawer_open {
  margin-left: var(--drawer-width);
}
