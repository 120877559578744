.tenant_name_wrap {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.tenant_name {
  width: 170px;
  height: 30px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}
