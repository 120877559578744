@mixin mq($breakpoint) {
  @if $breakpoint == 'sp' {
    @media (width <= 576px) {
      @content;
    }
  } @else if $breakpoint == 'pc' {
    @media (width >= 577px) {
      @content;
    }
  }
}
