:root {
  // Break Points
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;

  // Colors
  // --------------------------------------------------

  // Theme
  --primary: #2a78b6;
  --light-primary: #99bedc;
  --secondary: #4caf50;
  --text-black: rgba(0, 0, 0, 0.87);
  --text-gray: rgba(83, 83, 83, 0.87);
  --mention-bg: #e0f1ff;

  // Layout
  --base-background-color: #f4f4f4;

  // Text
  --text-anchor-link: #2a78b6;

  // Status
  --status-notstarted: #b1b1b1;
  --status-inprogress: #42b4d8;
  --status-completed: #5e5e64;

  // Priority
  --priority-low: #0ea5e9;
  --priority-medium: #fcd34d;
  --priority-high: #ef4444;
  --priority-background: #efefef;

  // colors
  --caution-red: #fecaca;
  --caution-red-text: #ef4444;
  --highlight-yellow: #fef9c3;

  // Size
  // --------------------------------------------------
  --header-height: 64px;
  --header-height-sp: 56px;
  --footer-height: 70px;
  --chatinput-height: 70px;
  --drawer-width: 240px;

  // Transition
  --default-ease-in-out: cubic-bezier(0.4, 0, 0.2, 1);
}
