.header_wrap {
  color: var(--text-black) !important;
  background-color: var(--base-background-color) !important;
  transition: width 0.3s var(--default-ease-in-out) !important;
}

.logo {
  width: 150px;
  height: auto;
}

.drawer_wrap {
  :global(.MuiPaper-root) {
    width: var(--drawer-width);
  }
}

.drawer_top {
  display: flex;
  align-items: center;
  height: var(--header-height);
  padding-left: 24px;
}

.drawer_link_wrap {
  width: 100%;
}

.version_wrap {
  position: absolute;
  bottom: 0;
  font-size: 0.8rem;
  color: var(--text-black);
}

.tenant_selector_wrap {
  padding: 1rem;
}
