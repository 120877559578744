@use 'src/styles/_icn.scss';

.wrap {
  right: 0;
  height: var(--footer-height);
  margin-left: 0;
  background-color: var(--primary);
  transition: width 0.3s var(--default-ease-in-out) !important;
}

.drawer_open {
  width: calc(100% - var(--drawer-width)) !important;
  margin-left: var(--drawer-width) !important;
}

.footerMenu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: var(--footer-height);
}

.buttonText {
  width: 100%;
  font-size: 0.8rem;
  font-weight: bold;
  text-align: center;
}

.disabled {
  opacity: 0.5;
}

.MuiBadge-root {
  // somestyle
  :global(.MuiBadge-badge) {
    color: var(--primary);
    background-color: #fff;
  }
}
