@use 'theme';
@use 'mui';
@use 'icn';
@use 'mixin' as m;
@tailwind base;
@tailwind components;
@tailwind utilities;

:global(:root) {
  --font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono', 'Roboto Mono',
    'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro', 'Fira Mono', 'Droid Sans Mono',
    'Courier New', monospace;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: -apple-system, blinkmacsystemfont, 'Helvetica Neue', 'Hiragino Kaku Gothic ProN',
    meiryo, sans-serif;
  /* stylelint-disable-next-line declaration-property-unit-allowed-list */
  font-size: 16px;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  background-color: var(--base-background-color);
}

// scrollbar
::-webkit-scrollbar-track {
  background-color: rgb(229, 229, 229);
  border-radius: 12px;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.44, rgb(213, 213, 213)),
    color-stop(0.72, rgb(191, 191, 191))
  );
  border-radius: 10px;
}

// emoji picker用のスタイルはGlobalで設定する
em-emoji-picker {
  position: absolute;
  bottom: 0;
  z-index: 50;
  width: 100%;
  height: 60dvh;
  min-height: 360px;
  max-height: 500px;

  @include m.mq(sp) {
    left: 0;
  }

  @include m.mq(pc) {
    right: 20px;
    max-width: 375px;
  }

  // emojimart用のborder radius
  --border-radius: 10px 10px 0px 0px;
}
