@keyframes slide-up {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes slide-down {
  from {
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(100%);
  }
}

.modal_animation_wrap {
  animation: slide-up 0.3s ease-out;
}

.modal_animation_down {
  animation: slide-down 0.3s ease-out;
  animation-fill-mode: forwards;
}
